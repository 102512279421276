import './index.scss'
import Mikaela from './Mikaela'
import Redact from './Redact'

export default function Work() {
  return (
    <>
      <Mikaela />
      <Redact />
    </>
  )
}
