import './index.scss'
import vivus from 'vivus'
import React from 'react'

class Logo extends React.Component {
  componentDidMount() {
    new vivus('Layer_1', { duration: 150 })
  }
  render() {
    return (
      <div className="logo-container">
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 723.72 422.07"
        >
          <g className="svg-container">
            <path
              className="logo-outline"
              d="M70.98,38c31.64,32.45,63.03,64.66,93.84,96.27-43.12,43.23-87.53,87.74-131.35,131.67-8.99-8.91-18.72-18.55-28.56-28.31,33.82-33.89,68.26-68.39,102.19-102.39C72.02,100.17,37.55,65.69,2.82,30.97,13.1,20.62,22.26,11.28,31.66,2.19c.61-.59,3.63,.3,4.7,1.35,11.55,11.31,22.94,22.78,34.62,34.46Z"
            />
            <path
              className="logo-outline"
              d="M234.23,251.91c41.29,0,82.09,0,122.88,0q6.64,0,6.66,6.61c.01,11.49-.11,22.98,.07,34.47,.06,3.92-.94,5.62-5.28,5.61-58.44-.12-116.89-.11-175.33-.01-3.76,0-4.86-1.25-4.81-4.9,.18-12.32,.18-24.64,0-36.96-.05-3.69,1.13-4.92,4.86-4.88,16.82,.16,33.63,.07,50.95,.07Z"
            />
            <path
              className="logo-outline"
              d="M498.98,53.75c0-12.66,.12-24.83-.07-36.99-.06-3.83,1.03-5.31,5.07-5.19,8.99,.27,18,.28,26.99,0,4.27-.14,5.31,1.43,5.29,5.47-.13,36.32-.08,72.64-.07,108.97q0,7.52,7.74,7.52c2.5,0,5,.03,7.5-.02,7.88-.17,14.38-2.96,19.73-9.08,23.22-26.6,46.62-53.04,69.98-79.52,7.71-8.74,15.51-17.41,23.27-26.11,4.24-4.75,9.59-7.07,15.93-7.09,10.41-.03,20.82,0,31.55,.65-39.16,44.17-76.86,89.29-117,131.89,18.28,12.59,30.48,32.43,45.4,49.65,25.84,29.82,50.83,60.37,76.17,90.62,1.02,1.22,1.95,2.52,3.51,4.56-2.37,.25-3.76,.53-5.16,.53-8.66,.04-17.33-.05-25.99,.03-7.6,.07-13.32-2.51-18.49-8.7-17.83-21.34-36.33-42.1-54.56-63.1-12.33-14.21-24.88-28.25-36.87-42.74-7.08-8.56-15.2-13.67-26.65-12.62-3.97,.36-8,.17-11.99,.02-3.02-.11-4.31,1.12-4.08,4.12,.1,1.32,.01,2.66,.01,4,0,37.65-.08,75.31,.1,112.96,.02,4.81-1.29,6.39-6.15,6.15-7.81-.39-15.66-.1-23.49-.1q-7.67,0-7.67-7.45c0-75.98,0-151.95,0-228.43Z"
            />
          </g>
        </svg>
      </div>
    )
  }
}

export default Logo
